<template>   
        <v-btn
            elevation="3"
            rounded
            :color="color"
            :to="link"
        >{{text}}</v-btn>
</template>

<script>
export default {
    props:{
        text: {
            default: "",
            type: String
        },
        color: {
            default: "white",
            type: String
        },
        link: {
            default: "",
            type: String
        }
    }
}
</script>

<style>

</style>