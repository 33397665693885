<template>
    <nav>
      <v-app-bar app dark fixed color="grey darken-4" v-if="$vuetify.breakpoint.mdAndUp">
         <v-spacer></v-spacer>
        <v-toolbar-title class="mr-5">               
           </v-toolbar-title>
       
        <v-toolbar-items>
         <v-btn
           v-for="item in nav"
           :key="item.id"
           @click="goToMenu(item.title,item.id)"
           :title="item.title"          
           plain
            color="grey lighten-5"
            class="font-weight-regular text-capitalize included" 
         >{{ item.title }}</v-btn>
        </v-toolbar-items>
        <v-spacer></v-spacer>
      </v-app-bar>
      
      <v-app-bar app dark fixed color="grey darken-4" v-else>
        <v-toolbar-title class="mr-5">              
           </v-toolbar-title>
           <v-spacer></v-spacer>
           <div></div>
           <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  plain
                  color="grey lighten-5"
                  class="font-weight-regular text-capitalize included" 
                  v-bind="attrs"
                  v-on="on"
                >
                <v-icon>mdi-menu</v-icon>
                </v-btn>
              </template>
              <v-list dark>
                <v-list-item
                   v-for="item in nav"
                  :key="item.id"
                  link
                  @click="goToMenu(item.title,item.id)"
                >
                  <v-list-item-title >{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>   
      </v-app-bar>
    </nav>
</template>

<script>
export default {
    name: "Navbar",
    data(){
        return {
            dialog : false,
            nav: [
                { title: 'Rólunk', id: '#about'},
                { title: 'Programok', id: '#programs'},
                { title: 'Árak', id: '#price'},
                { title: 'Utazási tájékoztató', id: '#covid'},
                
            ],
            options: {
              offset: 0,
              easing: "easeInOutQuart",
              duration: 700,
            }
        }
    },

    methods: {
      goToMenu: function(title,target){  

        if(this.$route.path !="/") this.$router.push('/',()=>{
          setTimeout(()=>{
            if(target) this.$vuetify.goTo(target, this.options)
            this.$emit("click",title);
          },500)          
        });
        else {
          if(target) this.$vuetify.goTo(target, this.options)
          this.$emit("click",title);
        }
      }
    }

    
}
</script>

<style>

</style>