
 <template>
  <div>
  
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
      @click:outside="disable"  
      
    >     
      <v-card>
        <v-card-title>
          <span class="text-h5">Alpesí'hét 2022 - Regisztráció </span>
          <v-spacer></v-spacer>
          <span><strong class="red--text text-h5">{{early_bird ? "EARLY BIRD": ""}}</strong></span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <ValidationObserver ref="form">
              <form>
            <v-row>
              <v-col
                cols="12"
                sm="8"
                md="8"
              >
              <validation-provider
                    v-slot="{ errors }"
                    name="saját név"
                    rules="required"
                  >
              <v-text-field
                  v-model="name"
                  :error-messages="errors"
                  label="Név *"
                  required
                ></v-text-field>
                
              </validation-provider>   
              </v-col>   
     
             
              <v-col
                  cols="12"
                  sm="8"
                  md="8"
                >
                 <validation-provider
                    v-slot="{ errors }"
                    name="email"
                    rules="required|email"
                  >
                    <v-text-field
                      v-model="email"
                      :error-messages="errors"
                      label="E-mail *"
                      required
                    ></v-text-field>
                  </validation-provider>
              </v-col>
              <v-col
                  cols="12"
                  sm="5"
                  md="5"
                >

                <validation-provider
                    v-slot="{ errors }"
                    name="születési hely"
                    rules="required"
                  >
                  <v-text-field
                    v-model="born_place"
                    :error-messages="errors"
                    label="Születési hely *"
                    required
                  ></v-text-field>
                </validation-provider>
              </v-col>

              <v-col
      cols="12"
      sm="6"
      md="4"
    >
      <v-dialog
        ref="dialog"
        v-model="modal"
        :return-value.sync="date"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="computedDateFormatted"
            label="Születési idő"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          scrollable
          @blur="date = parseDate(dateFormatted)"
          locale="hu-HU"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="modal = false"
          >
            Mégsem
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.dialog.save(date)"
          >
            Kiválaszt
          </v-btn>
        </v-date-picker>
      </v-dialog>
    </v-col>

              <v-col
                cols="12"
                sm="8"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Telefonszám"
                  :rules="{
                    required: true,
                    regex: '^[\/+](36)\\d{9}$'
                  }"
                >
                  <v-text-field
                    v-model="phone_number"
                    :counter="12"
                    :error-messages="errors"
                    label="Telefonszám"
                    hint="Formátum +35112223333"
                    required
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col
                cols="12"
                sm="8"
              >
                 <validation-provider
                    v-slot="{ errors }"
                    name="igazolvány szám"
                    rules="required"
                  >
                  <v-text-field
                    v-model="card_number"
                    :counter="10"
                    :error-messages="errors"
                    label="Személyigazolvány / Útlevél szám"
                    required
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col
                cols="12"
                sm="8"
              >
                <div class="text-subtitle-1">
                  Utazási mód
                </div>
              </v-col>


              <v-col
                cols="12"
                sm="12"
              >
              <v-radio-group 
                v-model="travel"
                row
                mandatory
              >
                  <v-radio
                    label="Busz"
                    value="bus"
                  ></v-radio>
                  <v-radio
                    label="Egyénileg oldom meg / részvételi díj 60€-val kevesebb"
                    value="car"
                  ></v-radio>
                </v-radio-group>
              </v-col>


               <v-col
                cols="12"
                sm="8"
              >
                <div class="text-subtitle-1">
                 Érvényes diákigazolvánnyal rendelkezem
                </div>
              </v-col>
             

              <v-col
                cols="12"
                sm="12"
              >
               
               <v-radio-group 
                v-model="student_card"
                row
                mandatory
              >
                  <v-radio
                    label="Igen és érvényes is lesz még 2022. január 31-én"
                    value="has_student"
                  ></v-radio>
                  <v-radio
                    label="Nem"
                    value="no_student"
                  ></v-radio>
                </v-radio-group>
              </v-col>


               <v-col
                cols="12"
                sm="8"
              >
                <div class="text-subtitle-1">
                 Csoportos felszerelés bérlés érdekelne?
                </div>
              </v-col>  

              <v-col
                cols="12"
                sm="12"
              >
                
               <v-radio-group 
                v-model="accessories"
                row
                mandatory
              >
                  <v-radio
                    label="Igen"
                    value="rent_accessories"
                  ></v-radio>
                  <v-radio
                    label="Nem, van saját felszerelésem"
                    value="no_rent_accessories"
                  ></v-radio>
                </v-radio-group>
              </v-col>

               <v-col
                cols="12"
                sm="8"
              >
                <div class="text-subtitle-1">
                 Legközelebbi hozzátartozó neve és elérhetősége
                </div>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <validation-provider
                    v-slot="{ errors }"
                    name="név"
                    rules="required"
                  > 
              <v-text-field
                  v-model="closest_name"
                  :error-messages="errors"
                  label="Név *"
                  required
                ></v-text-field>

                </validation-provider>
              </v-col>


               <v-col
                cols="12"
                sm="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="telefonszám"
                  :rules="{
                    required: true,
                    regex: '^[\/+](36)\\d{9}$'
                  }"
                >
                  <v-text-field
                    v-model="closest_phone"
                    :counter="12"
                    :error-messages="errors"
                    label="Telefonszám *"
                    hint="Formátum +35112223333"
                    required
                  ></v-text-field>
                </validation-provider>
              </v-col>
             

              <v-col
                cols="12"
                sm="10"
                md="10"
              >               
                <v-textarea
                    name="comment"
                    auto-grow
                    v-model="comment"
                    label="Megjegyzés (kivel akarsz egy szálláson lenni, stb...)"
                    value=""
                    rows="1"
                ></v-textarea>

              </v-col>


               <v-col
                cols="12"
                sm="8"
              >
                <div class="text-subtitle-1">
                 Általános szerződési feltételek
                </div>
                <div class="text-subtitle-2 mt-2">
                 Az általános szereződési feltételeket <a href="https://firebasestorage.googleapis.com/v0/b/weblap-533f9.appspot.com/o/Szerz%C5%91d%C3%A9si-%C3%A9s-korona-v%C3%ADrus-speci%C3%A1lis-felt%C3%A9telek.pdf?alt=media&token=03efc035-5802-4f8a-974d-0a42775bc156" target="_blank">ide kattintva</a> tudod letölteni!
                </div>
              </v-col>

               <v-col
                cols="12"
                sm="10"
                md="10"
              > 
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="adatvédelmi szerződés"
                >
                  <v-checkbox
                    v-model="general_contract"
                    :error-messages="errors"
                    value="1"
                    label="Elfogadom az általános szerződési feltételeket!"
                    type="checkbox"
                    required
                  ></v-checkbox>
                </validation-provider>

               </v-col>

              <v-col
                cols="12"
                sm="8"
              >
                <div class="text-subtitle-1">
                 Koronavírus - speciális feltételek
                </div>
                <div class="text-subtitle-2 mt-2">
                 A koronavírus helyzet által teremtett speciális feltételeket <a href="https://firebasestorage.googleapis.com/v0/b/weblap-533f9.appspot.com/o/Szerz%C5%91d%C3%A9si-%C3%A9s-korona-v%C3%ADrus-speci%C3%A1lis-felt%C3%A9telek.pdf?alt=media&token=03efc035-5802-4f8a-974d-0a42775bc156" target="_blank">ide kattintva</a> tudod letölteni!
                </div>
              </v-col>

               <v-col
                cols="12"
                sm="10"
                md="10"
              > 
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="szerződés"
                >
                  <v-checkbox
                    v-model="covid_contract"
                    :error-messages="errors"
                    value="1"
                    label="Elfogadom a koronavírus helyzet által teremtett speciális feltételeket!"
                    type="checkbox"
                    required
                  ></v-checkbox>
                </validation-provider>

               </v-col>


               <v-col
                cols="12"
                sm="10"
                md="10"
              >               
                <v-textarea
                    name="job"
                    v-model="job"
                    auto-grow
                    label="Ha különleges foglalkoztatásod van, itt tudod jelezni. "
                    hint="Kérlek írd le pontosan a pozíciódat!"
                    value=""
                    rows="1"
                ></v-textarea>

              </v-col>


              <v-col
                cols="12"
                sm="8"
              >
                <div class="text-subtitle-1">
                 Személyes adatok
                </div>                
              </v-col> 

               <v-col
                cols="12"
                sm="10"
                md="10"
              > 
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="nyilatkozat"
                >
                  <v-checkbox
                    v-model="data_contract"
                    :error-messages="errors"
                    value="1"
                    label="Elfogadom és hozzájárulok, hogy Turán Evelin, mint Főszervező a személyes adataimat a sítábor szervezéséhez és értesítéseihez felhasználja."
                    type="checkbox"
                    required
                  ></v-checkbox>
                </validation-provider>

               </v-col>
            </v-row>
              </form>
            </ValidationObserver>
          </v-container> 
                   
        </v-card-text>         
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="disable"
          >
            Mégsem
          </v-btn>
           <v-btn
            color="green darken-1"           
            text
            :disabled="!registration_enable"
            @click="sendRegistration"
          >
            Regisztráció
          </v-btn>  
        </v-card-actions>               
      </v-card>     
    </v-dialog>    
  </v-row>

  <div class="text-center">
    <v-dialog
      v-model="success_dialog"
      width="500"
    >
        <v-card>
        <v-card-title class="text-h5 green--text lighten-2">
          Sikeres regisztráció!
        </v-card-title>

        <v-card-text>
          Sikeresen regisztráltál az Alpesí'hét 2022 síelésre!<br/>
          <strong class="red--text">Ne felejtsd el Evelinnek eljuttatni az előleget a megadott határidőig!</strong>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            text
            @click="success_dialog = false"
          >
            Megértettem!
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>


           <v-snackbar
            :timeout="5000"
            v-model="warning_prop"
            bottom
            shaped
            color="warning"
          >
            Helytelenül kitöltött mezők!
          </v-snackbar>
           <v-snackbar
            :timeout="5000"
            v-model="error_prop"
            bottom
            shaped
            color="warning"
          >
            Regisztrációs hiba! Kérlek vedd fel velünk a kapcsolatot! [{{error_code}}]
          </v-snackbar>  
  </div>
</template>

<script>
import { required, digits, email, max, regex } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import axios from 'axios'

setInteractionMode('eager')

  extend('digits', {
    ...digits,
    message: 'Minimum {length} karakterből kell állnia!',
  })

  extend('required', {
    ...required,
    message: 'A(z) {_field_} nem lehet üres',
  })

  extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters',
  })

  extend('regex', {
    ...regex,
    message: 'A(z) {_field_} nem érvényes formátumú! (Formátum +35112223333)',
  })

  extend('email', {
    ...email,
    message: 'Az email címnek megfelelőnek kell lennie',
  })


export default {


    

    components : {
      ValidationProvider,
      ValidationObserver,
    },
    data: vm => ({
      dialog: false,
      early_bird : Date.now() < new Date('October 10, 2021 00:00:00'),
      success_dialog : false,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      modal: false,
      travel: "",
      student_card: "",
      accessories: "",
      errors: "",
      email: "",
      name: "",
      born_place: "",
      phone_number: "",
      card_number: "",
      closest_phone: "",
      closest_name: "",
      general_contract: "",
      covid_contract: "",
      data_contract: "",
      error_prop: false,
      warning_prop: false,
      error_code : "",
      job : "",
      comment : "",
      registration_enable : true
    }),

    

    methods : {
      enable : function(){
        this.dialog = true
      },
      disable : function(){        
        this.dialog = false
        this.travel = this.student_card = this.accessories = this.email = this.name = this.born_place = this.born_time = this.phone_number = this.card_number = this.closest_phone = this.closest_name = this.general_contract = this.covid_contract = this.data_contract = this.comment = this.job = ""
        this.$nextTick(() => {
          this.$refs.form.reset()
        });
      },

      
      

      parseDate: function (date) {    
        if (!date) return null
        const [year,month, day, ] = date.split('-')
        return `${year}.${month.padStart(2, '0')}.${day.padStart(2, '0')}.`
      }, 
      
      isCompleted : function() {
        return 
      },

      include () {
        return [document.querySelector('.included')]
      },

      sendRegistration : function(){
        this.$refs.form.validate().then(success => {
          if (!success) {
            this.warning_prop = true
            return;
          }
          
          this.registration_enable = false
          axios.post('https://europe-west1-weblap-533f9.cloudfunctions.net/api/registration', {
            travel: this.travel,
            student_card: this.student_card,
            accessories: this.accessories,
            errors: this.errors,
            email: this.email,
            name: this.name,
            born_place: this.born_place,
            born_time: this.computedDateFormatted,
            phone_number: this.phone_number,
            card_number: this.card_number,
            closest_phone: this.closest_phone,
            closest_name: this.closest_name,
            general_contract: this.general_contract,
            covid_contract: this.covid_contract,
            data_contract: this.data_contract,
            job: this.job,
            comment: this.comment
          }).then((response) => {
          //null everything
          this.travel = this.student_card = this.accessories = this.email = this.name = this.born_place = this.born_time = this.phone_number = this.card_number = this.closest_phone = this.closest_name = this.general_contract = this.covid_contract = this.data_contract = this.comment = this.job = ""
          this.$nextTick(() => {
            this.$refs.form.reset()
          });
          this.registration_enable = true
          this.dialog = false
          this.success_dialog = true
          }, (error) => {
            this.registration_enable = true
            this.error_prop = true
            this.error_code = error
          });

        })
    },
    },

    computed: {
      computedDateFormatted: function() {
        return this.parseDate(this.date)
      },      
    },

}
</script>
    
<style scoped>


</style>