<template>
   <v-col
    cols="11"
    sm="11"
    md="11"
    :lg="5"
    >
     <v-card       
        hover
     >
         <v-carousel
        hide-delimiters
        >
            <v-carousel-item
            v-for="(item,i) in items"
            :key="i"            
            >
                <v-img
                    aspect-ratio="16/9"
                    :src="item"
                    height="100%"
                >
                    <template 
                    v-slot:placeholder
                    height="100%"
                    >

                        <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                        >
                        <v-progress-circular
                            indeterminate
                            color="white darken-3"
                        ></v-progress-circular>
                        </v-row>
                    </template>  
                </v-img>
            </v-carousel-item>
        </v-carousel>
     </v-card>
   </v-col>
 
</template>

<script>
import ImageUtil from "@/utils/imageUtils"  
export default {    
    data () {
      return {
        items: []
      }
    },
    created: function () {
        for (let i = 1; i < 38; ++i) {
            this.items.push(this.getImageByScreen('about_images/'+i+'.jpg','about_images/'+i+'.jpg','about_images/'+i+'.jpg'));
        }
    },
     methods: {
      getImageByScreen : ImageUtil.getImageByScreen,
      isMobile: ImageUtil.isMobile
    },
  }
</script>

<style>

</style>