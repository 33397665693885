<template>
    <div
        class="mx-5"
    >
        <v-row class="mt-15">
            <v-col
                class="text-h5 font-weight-medium text-center"     
            >
            Az Alpesí'hét tájékoztatója az utazási feltételekről
            </v-col>
        </v-row>

        <v-row class="mt-15">

            Franciaország 3 jelzéssel jelöli a vírus terjedése szerint a világ országait (zöld, narancssárga, piros).<br/>
            Magyarország jelenleg az európai térség többi országával együtt a zöld zónába tartozik, ez KARANTÉNMENTES beutazást jelent.  
        </v-row>   

        <v-row class="mt-15">
            Az országba beutazáshoz egy becsületbeli nyilatkozat kitöltése szükséges, amivel a kitöltő igazolja, hogy nincsenek koronavírus-fertőzésre utaló tünetei, és az utolsó 14 napban nem találkozott igazolt koronavírus fertőzöttel, továbbá az alábbi igazolás:
            <br/>
            12 év felett szükséges egy dokumentum az alábbiak közül:<br/>
            A) OLTÁS esetén: védettséget igazoló európai digitális COVID pass (elektronikus vagy papír alapú). Elfogadott vakcinák: Pfizer, Moderna, Astra-Zeneca, Johnson/Johnson. (a második adag után 7 napnak kell eltelnie, a Johnson/Johnson után 4 hétnek).
            A magyar védettségi igazolványt nem fogadják el.
            <br/>
            B) TESZT: francia vagy angol nyelvű igazolás szükséges egy 72 óránál nem régebbi negatív PCR vagy antigén gyorstesztről. PCR teszt irodánk szervezésében már 7.500 Ft-ért elérhető, az antigén gyorstesztek ára pedig 5.000-6.500 Ft körül mozog.
            Teszttel tudnak tehát utazni az oltással nem rendelkezők, vagy akiknek az oltási folyamata nem lezárt, illetve azok, akiknek az oltás típusát nem fogadják el (pl. Sinopharm vagy Sputnik-V).
        </v-row>

         <v-row class="mt-15">
            Jelenleg Magyaroszág közúti határain NINCS Covid19 ellenőrzés, a schengeni belső határainkon pedig nem is áll rendőr, vagyis teljesen szabad a hazatérés akár védettségi igazolvánnyal, akár anélkül. Ez feltehetően szigorodik majd, mi arra számítunk, hogy visszatér a védettségi igazolványok (vagy EUs covid19 igazolványok) ellenőrzése, és ehhez kötik majd a szabad belépést.
            A repülőtérre érkezve jelenleg is ellenőrzik a belépőket: a védettséggel nem rendelkezők számíthatnak karanténra, ez azonban kiváltható 2 db. PCR teszttel, a védettséget igazolók (védettségi kártya, EUs covid19 igazolvány) vagy üzleti úton (is) részt vevők pedig továbbra is szabadon beléphetnek.        
         </v-row>

    </div>

</template>

<script>
export default {

}
</script>

<style>

</style>