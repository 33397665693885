<template>
    <v-col
        cols="12"
        sm="12"
        md="12"
        :lg="max_width"
    >
        <v-card   
            hover
            height="100%"
        >           
            <v-card-title
               class="justify-center" 
            >   
                {{title}}    
            </v-card-title>            
            <v-card-subtitle
                v-if="subtitle"
                 class="text-center red--text text-subtitle-2" 
            >
                {{subtitle}}
            </v-card-subtitle>
            <div
                v-else
                class="mb-5"
            />
            <v-col 
                :class="color+' py-10'"                
            >
                <div
                    class="text-center text-h3 white--text"  

                >
                   {{price}}
                </div> 
 
            </v-col>
            
                <div
                    class="text-center grey--text my-2"
                    v-html="sub_price" 
                >
                </div>
             <v-col 
            >
               <v-simple-table>
                    <template v-slot:default>
                    <tbody>
                        <tr
                            v-for="item in data_table"
                        >
                            <td>{{item[0]}}</td>
                            <td>{{item[1]}}</td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
            </v-col>  
        </v-card>
    </v-col>
  
</template>

<script>
export default {
    props: {
        title: {
        type: String,       
        },
        subtitle: {
        type: String,       
        },
        max_width : {
            type: String, 
            default: "5",
        },
        max_height : {
            type: String, 
            default: "200", 
        },
       
        price : {
            type: String,          
        },
        sub_price : {
            type: String,          
        },
        color : {
            type: String,
            default: "green"            
        },
        data_table : {
            type: Array,
        }
    },
}
</script>

<style>

</style>