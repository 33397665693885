<template>
  <v-footer
    dark
    padless
    class="mt-10"
  >
    <v-card
      width="100%"
      flat
      tile      
      class="grey darken-4 white--text text-center"
    >
      <v-card-text>
        <v-btn
          v-for="icon in icons"
          :key="icon.icon"
          class="mx-4 white--text"
            :href="icon.path"
            target="_blank"
          icon
        >
          <v-icon size="24px">
            {{ icon.icon }}
          </v-icon>
        </v-btn>
      </v-card-text>

      <v-card-text class="white--text pt-0">
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="white--text">
        2018 - {{ new Date().getFullYear() }} — <strong>Alpesí'hét</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
    data: () => ({
      icons: [
        {
            icon: 'mdi-facebook',
            path: 'https://www.facebook.com/turan.evelin/'    
        },
        {
            icon: 'mdi-instagram',
            path: 'https://www.instagram.com/alpesi_het/'
        },
      ],
    }),
  }
</script>

<style>

</style>