<template>
  <v-col
    cols="11"
    sm="11"
    md="11"
    :lg="max_width"
    >
   <v-card
  >
     <v-card-title>{{title}}</v-card-title>  
    <v-card-subtitle>{{subtitle}}</v-card-subtitle>
    <v-card-text>     
      <div class="text--primary" v-html="text">        
      </div>
    </v-card-text>
    <v-card-actions
      class="ml-2"
    >
      <v-btn
        :color="color"
        class="white--text mb-2"
        :href="target"
        :target="target?'_blank':''"     
        :to="to"
      >
        Tudj meg többet!
      </v-btn>
    </v-card-actions>
  </v-card>
  </v-col>
</template>

<script>
export default {
  props: {
    title : {
      type: String
    },
    subtitle : {
      type: String
    },
     text : {
      type: String
    },
     to : {
      type: String
    },
    target : {
      type: String
    },
    color : {
      type: String
    },
     max_width : {
      type: String
    }
  }
}
</script>

<style>

</style>