<template>
  <v-app>  
     <Navbar 
      @click="navbarClick"
     />
    <v-main>
      <router-view ref="view"/>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
 import Navbar from "@/components/Navbar"
import Footer from "@/components/Footer"

export default {
  name: 'App',

  components: {
    Navbar,
    Footer
  },

  data: () => ({
    //
  }),


  methods: {
    navbarClick : function(menu_name){
      if(menu_name == "Jelentkezés"){
        this.$refs.view.registrationClick();
      }
    }
  }

};
</script>

<style>
    body {
    overflow: visible;
}

   .parallax img {
     min-width: 100%;
     max-width: 300%;
     min-height: 150%;
     max-height: 300%;
  }

  
::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.87);;
	border-radius: 10px;
}

::-webkit-scrollbar
{
	width: 0.2vw;
	opacity: 0;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(33, 150, 243, 1);
	background-color: rgba(255, 255, 255, 0);
}
</style>
