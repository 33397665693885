<template>
  <v-col
    cols="12"
    sm="12"
    md="12"
    :lg="max_width"
    >
    
  <v-card   
    hover
    height="100%"
    justify="center"
  >
   
  <v-card-title> 
    <v-avatar
      :color="color"
      size="80"
      class="mt-n15 mx-auto"
    >
        <v-icon x-large color="white">{{icon}}</v-icon>
    </v-avatar>       
  </v-card-title>
  <v-card-subtitle
    class="text-center mt-1"
  >
    
    <p class="text-h5 text--primary">
        {{title}}
       
    </p>
     <v-row> 
          <v-col>
            <v-divider style="width:80%;" class="mx-auto grey lighten-2"></v-divider>
          </v-col>
    </v-row>
    <p class="subtitle-1 font-weight-light mt-3" v-html="subtitle">
      
    </p>
    
  </v-card-subtitle>

   

    <v-card-text 
        class="text--primary text-center mt-n5"                    
    >   
        <div class="text--primary mb-5" v-html="text">           
        </div>    
        <div class="text--primary font-weight-bold" v-if="list" v-for="element in list" :key="element.text">
          <v-icon color="green" small>{{ element.icon }}</v-icon> {{ element.text }}
        </div>   
    </v-card-text>  
    
  </v-card>
    </v-col>
</template>

<script>
export default {
    props: {
        title: {
        type: String,       
        },
        text: {
        type: String,       
        },
        max_width : {
            type: String, 
            default: "5",
        },
        max_height : {
            type: String, 
            default: "200", 
        },
        icon : {
            type: String,            
        },
        subtitle : {
            type: String,            
        },
        color : {
            type: String,
            default: "green"            
        },
        list : {
          type: Array,
          default: null
        }
    },
}
</script>

<style scoped>
  
</style>