export default {


    getImageByScreen: function(large_image,medium_image,small_image){
        if(this.$vuetify.breakpoint.lgAndUp) return require('@/assets/'+large_image);
        else if(this.$vuetify.breakpoint.mdAndUp) return require('@/assets/'+medium_image);
        else return require('@/assets/'+small_image);
    },

    isMobile: function(){
        return this.$vuetify.breakpoint.smAndDown
    }



}