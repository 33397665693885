<template>

  <v-card class="mt-10 mx-5"
    shaped
    max-width="700"
    hover
  >
    <v-card-title>
        <v-avatar 
          size="150"  
          class="mt-n10 ml-n10"         
        >
          <img
            alt="user"
            :src="getImageByScreen(name+'.jpg',name+'.jpg',name+'.jpg')"
          >
        </v-avatar> 
        <div class="col-8">
          <div class="font-weight-bold ml-5">
              {{this.names[this.name]}}
          </div>
          <div class="font-weight-light ml-5">
              {{this.work[this.name]}}
          </div> 
        </div>          
    </v-card-title>
    <v-card-text class="font-weight-bold grey--text text--darken-2">        
          {{this.descriptions[this.name]}}
    </v-card-text>
  </v-card>
</template>

<script>
import ImageUtil from "@/utils/imageUtils"  
export default {
    props: {
      name: {
        type: String,
        default: "Janos"
      }
    },

    methods: {
      getImageByScreen : ImageUtil.getImageByScreen,
      isMobile: ImageUtil.isMobile
    },

    data : () => ({
      names: {
        "Evelin": "Turán Evelin"
      }, 
      work: {
        "Evelin": "Az Alpesí'hét alapítója"
      },
      descriptions : {
        "Evelin": "Az első sítáboros szervezésem 2017-re nyúlik vissza. De persze előtte is  mentünk a barátokkal síelni, de akkor egy meglévő szervezésbe csatlakoztunk bele. Évről évre nőtt a kiscsapatunk és egyszer csak gondoltam egy nagyot, hogy saját magam szervezek egy sítábort! 2017-ben először Ausztriába mentünk síelni 35 fővel, majd ez a szám az évek során folyamatosan nőtt, egyre többen szereztek rólunk tudomást. 2021-ban sajnos a COVID miatt elmaradt az Alpe'sí hét táborunk, ahogy tavaly is (65 fővel), úgy idén is a francia Alpokat vesszük majd célba. Ha kérdésed lenne vagy csak informálódnál, keress bátran! :) "
      }
    }),

}
</script>

<style>



</style>