<template>
<div>
    <v-img
      max-height="600"
      :src="getImageByScreen('welcome.jpg','welcome.jpg','welcome.jpg')"
    ></v-img>   

     <v-row class="mt-15">
        <v-col
          class="text-h3 font-weight-medium  text-center"     
        >
          Köszöntünk az Alpesi'hét honlapján!
        </v-col>
      </v-row>  

      <v-row class="mt-15" id="about">
        <v-col
          class="text-h5 font-weight-medium text-center"     
        >
          Mi az az Alpesí'hét??
        </v-col>
      </v-row>  
     <v-row class="justify-center">
        <v-col
          class="my-5 col-8 text-center grey--text"     
        >
          Ez egy felekezetközi sítábor, ahol egy hét feltöltődés, pihenés, izgalmas - vadregényes táj, brutál jófej emberek és közösségi programok várnak!
        </v-col>
    </v-row>

    <v-row justify="center">
      <v-btn
        elevation="2"
        href="https://forms.gle/rmBoxY8Ey4HV16iJ6"
        target="_blank"
        color="red"
        class="white--text"
      >Regisztráció</v-btn>
    </v-row>

     

       <v-row class="mt-10"> 
        <v-col>
          <v-divider style="width:80%;" class="mx-auto grey lighten-2"></v-divider>
        </v-col>
      </v-row>

    <v-row class="mt-15" id="about">
        <v-col
          class="text-h5 font-weight-medium text-center"     
        >
          Rólunk mondták
        </v-col>
      </v-row>  
      <v-row class="justify-center">
        <v-col
          class="my-5 col-8 text-center grey--text"     
        >
          Az elmúlt években sokan választottak bennünket! Íme néhány tapasztalat, vélemény!
          </v-col>
      </v-row> 

      <v-row
        justify="center"
        class="mx-5"
       >

      </v-row>


       <v-row
        justify="center"
        class="mx-5"
       > 

         <ImageContainerCard 
          max_height="300"
          max_width="4"
          title="Új barátok és sok hó!"
          text='"Az egész hét nagyon jó volt. Most életemben először jutottam el snowboardozni, szóval volt mit eltanulnom a "nagyoktól"!
          <br/>Újbarátokra tettem szert és egy nagyon jó közösség része lettem.
          <br/>Ja és meg sose láttam azelőtt ennyi havat és téli gyerekként külön álomvált valóra!"
          <br/>- Sándor Zsanna -'          
          image_src='03.jpg'
          class="my-5"
        />


        <ImageContainerCard 
          max_height="300"
          max_width="4"
          title="Tutira megyek még!"
          text='"Az első Alpe’sí héttől majdnem minden évben részt vettem a sítáborban és az az igazság, hogy akárki akármit mond, ez a program pont annyira élvezhető, amennyire nyitott az ember. 
          <br/>Itt tanultam meg síelni, itt szereztem legjobb barátokat, itt éltem meg, milyen síelés közben patakzó könnyekkel dicsőítve csodálni a teremtett világot. 
          <br/>Jófej társaság, örök élmények - én tuti megyek, amíg csak tehetem!"
          <br/>- Polereczki Ráhel -'          
          image_src='04.jpg'
          class="my-5"
        />

      </v-row>

       <v-row
        justify="center"
        class="mx-5"
       > 

        <ImageContainerCard 
          max_height="300"
          max_width="4"
          title="Nem maradsz egyedül!"
          text='"Megéri-e elmenni? Ez nem kérdés, hiszen egy remek csapattal tölthetsz el egy hetet, gyönyörű hegyeket síelhetsz be és közben új élményekkel gazdagodhatsz.
<br/> Soha nem sieltél még?<br/>Akkor sem kell aggódnod, mert itt nem leszel egyedül. A csapat nagyon segítőkész és támogató! "<br/>
- Hege Péter -'
          image_src='01.jpg'
          class="my-5"
        />

        <ImageContainerCard 
          max_height="300"
          max_width="4"
          title="Életre szóló élmények!"
          text='"Aki első alkalommal jön, annak biztos, hogy felejthetetlen, örök élmény lesz! Aki pedig már volt, tudja miről maradhatna le, jó társaság, hangulat és finom ételek.
          <br/>Ha egyszer kipróbáltad már, garantáltan minden évben fogsz hódolni ezeknek a téli sportoknak.<br/>Vigyázat megfertőzhet!"
          <br/>- Jambrich Boglárka -'          
          image_src='02.jpg'
          class="my-5"
        />
       </v-row>


      <v-row 
        class="mt-10"
        justify="center"
      > 
        <ImageCarousel 
        />        

      </v-row>



      <v-row class="mt-10"> 
        <v-col>
          <v-divider style="width:80%;" class="mx-auto grey lighten-2"></v-divider>
        </v-col>
      </v-row>

    <v-row class="mt-15" id="about">
        <v-col
          class="text-h5 font-weight-medium text-center"     
        >
          Szervező
        </v-col>
      </v-row>  


      <v-row
        justify="center"
        class="mb-15"
       >

        <FaceCard name="Evelin"
                class="my-5"
            />

      </v-row>


      <v-row class="mt-10"> 
        <v-col>
          <v-divider style="width:80%;" class="mx-auto grey lighten-2"></v-divider>
        </v-col>
      </v-row>

      <v-row class="mt-15" id="programs">
        <v-col
          class="text-h5 font-weight-medium text-center"     
        >
          Mit is fogunk csinálni?
        </v-col>
      </v-row> 
           

      <v-row
        justify="center"
        class="mx-5"
      >
      
         <IconCard      
          class="my-10"
          title="Dátum és Helyszín"
          subtitle="Franciaország legszebb táján"
          text="2024. január 26 - február 4.<br>Franciaország, Valloire - Valmeinier 1800"
          max_width="3"
          icon="mdi-clock-time-five"
          color="green"
          :list='[
            {"icon": "mdi-check-circle-outline", "text":"Változatos magaslati terep"},
            {"icon": "mdi-check-circle-outline", "text":"Hóbiztos, több mint 400 hóágyú"},
            {"icon": "mdi-check-circle-outline", "text":"Freeride hómezők"},
            {"icon": "mdi-check-circle-outline", "text":"Giant snowpark"},
            {"icon": "mdi-check-circle-outline", "text":"A pályák 70%-a 2000 m felett"},
            {"icon": "mdi-check-circle-outline", "text":"Zone Chrono"},
            {"icon": "mdi-check-circle-outline", "text":"Barátságos apartmanok"},
            {"icon": "mdi-check-circle-outline", "text":"Tradicionális szavojai hangulat"},
          ]'
        />     

        <IconCard      
          class="my-10"
          title="Síterep"
          subtitle="160 km-es pályarendszer,<br/>1430 - 2750 méter magasságban,<br/>32 darab lifttel"
          text="Pálya kategóriák:<br/>Zöld: 34 km, Kék: 50 km<br/>Piros: 62 km, Fekete: 14 km"
          max_width="3"
          color="blue"
          icon="mdi-image-filter-hdr"
        />  

         <IconCard      
          class="my-10"
          title="Közösségi programok"
          subtitle="Családias esték"
          text="Az ott töltött 7 este alatt várhatóan több közösségi alkalmunk is lesz (mini esti áhitatok és dicsőítések), de persze a többi estén is törekszünk arra, hogy az egyes apartmanok között átjárhatóság legyen."
          max_width="3"
          color="red"
          icon="mdi-message-text-clock"
        />  

        <IconCard      
          class="my-10"
          title="Kezdő síelő vagy snowboardos vagy?"
          subtitle="Aggodalomra semmi ok!"
          text='A "táborunk" első napján (vasárnap) délelőtt igyekszünk minden fontos információt elmondani, illetve néhány lényeges technikai gyakorlattal ellátni, amiket a hét folyamán tudsz gyakorolni és tovább fejleszteni más kezdőkkel.<br/>Persze a tapasztaltabbaktól is tudsz segítséget, tanácsot kérni, de egész héten irányított “oktatás” a kezdődnek nem lesz.'
          max_width="3"
          color="cyan"
          icon="mdi-human-greeting-variant"
        />  

      </v-row>
       <v-row       
        justify="center"
        class="mx-5"
      >
          <IconCard      
          class="my-10"
          title="Szállás"
          subtitle="7 éjszaka, igényes ***-os szálláshelyen"
          text="6 fős 3 légteres apartmanokban, 6 fős és 8 fős chaletekben lesz a szállás, jól felszerelt, színvonalas berendezéssel. 
          <br>Ágyneműhuzat a helyszínen bérelhető (10,5 Eur/fő), de hozható is. Az épület pályaszállás, a legközelebbi felvonó mindössze 100 méterre található.
          <br>Ingyenes wellness használati lehetőség (fedett medence).
          <br>Az épület recepcióján ingyenesen biztosított a wifi, az apartmanban wifi elérhetőség vásárolható!
          <br>Bevásárlási lehetőség a szállástól kb. 750 méterre van.
          <br>Konyha felszereltsége: főzőlap, mikrosütő, hűtőszekrény, mosogatógép."
          max_width="3"
          color="orange"
          icon="mdi-bunk-bed"
        />
       </v-row>

        <v-row class="justify-center mt-10">
          <v-btn
            elevation="2"
            href="https://forms.gle/rmBoxY8Ey4HV16iJ6"
            target="_blank"
            color="red"
            class="white--text"
          >Regisztráció</v-btn>  
        </v-row>


       <v-row class="mt-10"> 
        <v-col>
          <v-divider style="width:80%;" class="mx-auto grey lighten-2"></v-divider>
        </v-col>
      </v-row>

      <v-row class="mt-15" id="price">
        <v-col
          class="text-h5 font-weight-medium text-center"     
        >
          Utazásról és a díjcsomagokról
        </v-col>
      </v-row>  

      <v-row
        justify="center"
        class="mx-5"
      >
         <IconCard      
          class="my-10"
          title="Utazás ideje"
          subtitle="Jó hangulatban gyorsan telik az idő!"
          text="Az utazási idő várhatóan 16-18 óra lesz.
                AZ ELSŐ 46-48 FŐ VIP BUSSZAL JÖHET!
                <br>(A pontos létszám függ, hogy milyen buszt sikerül lefoglalnunk.)"
          max_width="3"
          icon="mdi-timer"
          color="light-green"
        />     

        <IconCard      
          class="my-10"
          title="VIP busz"
          subtitle=""
          text="Mit jelent a VIP busz?<br>
              - megnövelt lábtér / 10-20 cm-rel nagyobb hely az ülések között<br>
              - 230 voltos csatlakozók üléspáronként<br>
              - exkluzív belsőtér<br>
              A VIP busz telítettsége esetén, a fentebb leírt feltételek hiányában, de ugyanúgy komfortos autóbusszal utazhatsz.<br>
              Jó társaságban, filmet nézve gyorsan telik az idő, ne aggódj!"
          max_width="3"
          color="pink"
          icon="mdi-bus-multiple"
        />  

         <IconCard      
          class="my-10"
          title="Utazás önállóan"
          subtitle="Utazás megoldása önszervezéssel"
          text="A részvételi díjad ennek megfelelően 80 euróval csökken. Minden felszerelésedről, csomagodról neked kell majd gondoskodni, az autóbuszra sajnos nem tehető fel.
          <br/>Jó ha figyelembe veszed, hogy a szállást / síbérletet és egyéb helyszíni feladatokat csak a csoportvezető végezheti el, aki busszal fog érkezni!
          <br/>(Turán Evelin)
          <br/>Az utazási módot 2023. október 29-ig módosíthatod vagy a lazy bird regisztrációd során leadott utazási módot vesszük figyelembe.<br/>
Módosítás illetve pénzvisszatérítés a fenti dátumok után nem garantált."
          max_width="3"
          color="teal"
          icon="mdi-car"
        />         

      </v-row>

       <v-row
        justify="center"
        class="mx-5 mb-10"
      >
          <PriceTable 
            title="Early Bird"
            color="green"
            price="415€/fő"
            sub_price="melyből 49€ SkiLines Klubkártya<br/>(nem visszatéríthető, a kártya névre szól, nem ruházható át)"
            max_width="3"
            :data_table= "[
              ['Regisztrációs határidő','2023.10.08.'],
              ['Előleg befizetésének határideje','2023.10.08.'],
              ['Előleg összege','50€/fő'],
              ['I. részlet befizetésének határideje','2023.11.18.'],
              ['I. részlet összege','150€/fő'],
              ['II. részlet befizetésének határideje','2023.12.16.'],
              ['II. részlet összege','215€/fő'],
              ['Teljes részvételi díj','415€/fő'],
            ]"
          />

          <PriceTable 
            title="Lazy Bird"
            subtitle="Amíg a szabad helyek tartanak!"
            color="blue"
            price="440€/fő"
            sub_price="melyből 49€ SkiLines Klubkártya<br/>(nem visszatéríthető, a kártya névre szól, nem ruházható át)"
            max_width="3"
            :data_table= "[
              ['Regisztrációs határidő','2023.11.05.'],
              ['Előleg befizetésének határideje','2023.11.05.'],
              ['Előleg összege','50€/fő'],
              ['I. részlet befizetésének határideje','2023.11.18.'],
              ['I. részlet összege','150€/fő'],
              ['II. részlet befizetésének határideje','2023.12.16.'],
              ['II. részlet összege','240€/fő'],
              ['Teljes részvételi díj','440€/fő'],
            ]"
          />

      </v-row>


        <v-row class="mt-10"> 
        <v-col>
          <v-divider style="width:80%;" class="mx-auto grey lighten-2"></v-divider>
        </v-col>
      </v-row>

      <v-row class="mt-15" id="covid">
        <v-col
          class="text-h5 font-weight-medium text-center"     
        >
          Tájékoztató kiutazással kapcsolatban
        </v-col>
      </v-row> 

      <v-row
        justify="center"
        class="mx-5 mb-15 mt-10"
      >
      <ContentCard 
        title="Magyar Konzuli tájékoztatás"
        subtitle=""
        text="A Magyar Kormány aktuális rendelkezése a külföldre utazás feltételeiről."
        target="https://konzuliszolgalat.kormany.hu/europa-utazasi-tanacsok?franciaorszag"
        color="red"
        max_width="3"
      
      />

      <ContentCard 
        title="Francia Konzuli tájékoztatás"
        subtitle=""
        text="Franciaország aktuális rendelkezése a külföldre utazás feltételeiről."
        target="https://www.france.fr/en/holiday-prep/coronavirus-covid-19-the-situation-in-france"
        color="blue"
        max_width="3"
      
      />


      </v-row>

     
</div>

</template>

<script>
  import ImageUtil from "@/utils/imageUtils"  
  import ImageCarousel from "@/components/ImageCarousel"
  import RoundedButton from "@/components/RoundedButton"
  import ImageContainerCard from "@/components/ImageContainerCard"
  import IconCard from "@/components/IconCard"
  import FaceCard from "@/components/FaceCard"
  import Registration from "@/components/Registration"
  import PriceTable from "@/components/PriceTable"
  import ContentCard from "@/components/ContentCard"

  export default {
    name: 'Home',

    components: {
      RoundedButton,
      ImageContainerCard,
      FaceCard,
      IconCard,
      PriceTable,
      ImageCarousel,
      Registration,
      ContentCard
    },

    methods: {
      getImageByScreen : ImageUtil.getImageByScreen,
      isMobile: ImageUtil.isMobile,

      registrationClick : function(){
        this.$refs.registrationPanel.enable()
      }
    }
  }
</script>
