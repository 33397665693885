<template>
    <v-col
    cols="12"
    sm="12"
    md="12"
    :lg="max_width"
    >
  <v-card   
    hover
    height="100%"
  >
    <v-img
      class="white--text align-end  px-3"
      :height="max_height"     
      :src="getImageByScreen(image_src,image_src,image_src)"
      gradient="to top, rgba(0,0,0,.3), rgba(0,0,0,0)"
    >
      <v-card-title       
      >{{title}}</v-card-title>
    </v-img> 
    <v-col 
    >
        <v-card-text 
        class="text--primary  px-7"        
        >
        <div v-html="text"></div>
        </v-card-text> 
    </v-col>  
  </v-card>
    </v-col>
</template>


<script>
  import ImageUtil from "@/utils/imageUtils"  
    export default {   
    name: "ImageContainerCard",     
    props: {
      title: {
        type: String,       
      },
       text: {
        type: String,       
      },
      image_src: {
        type: String,       
      },
    max_width : {
        type: String, 
        default: "5",
      },
    max_height : {
        type: String, 
        default: "200", 
    }
    },

     methods: {
      getImageByScreen : ImageUtil.getImageByScreen,
      isMobile: ImageUtil.isMobile
    },
}
</script>


<style scoped>

</style>

